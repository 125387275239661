#pdf-layout {
  .user-chat {
    position: relative !important;
  }
  #user-chat-show .chat-conversation, #attachment-chat .chat-conversation {
    height: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  #user-chat-show .user-chat-topbar, #attachment-chat .user-chat-topbar {
    position: relative;
  }
  .chat-conversation .right .conversation-list .ctext-wrap .ctext-wrap-content:before {
    border: none;
  }
  .chat-conversation .conversation-list .ctext-wrap-content:before {
    border: none;
  }

  #user-chat-show .user-chat-topbar, #attachment-chat .user-chat-topbar {
    height: unset;
  }
  .chat-conversation .conversation-list .ctext-wrap {
    margin-bottom: 0px;
  }
  .chat-conversation .right .conversation-list .user-chat-content {
    margin-bottom: 10px;
  }
}
