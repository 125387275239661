.user-profile-sidebar {
  .top-block {
    background-color: #4A154B3d;
    .fullname-block {
      justify-content: center;
      display: flex;
      align-items: center;

      .top-fullname {
        color: #fff;
      }

      .edit-icon {
        font-size: 20px;
        padding-left: 10px;
        color: white;
      }
    }

    .edit-wrapper {
      display: none;
      .edit-fullname-block {
        justify-content: center;
        display: flex;
        align-items: center;

        .fullname-input {
          background-color: unset;
          color: white;
          font-size: 16px;
          font-weight: bold;
          border: 1px solid;
          &:focus-visible {
            outline: none;
          }
        }

        .save-icon {
          font-size: 20px;
          padding-left: 10px;
          color: white;
        }
      }
    }
  }
  .user-profile-desc {
    .colorDisplay {
      background-color: #4A154B3d;
      border-radius: 100%;
      width: 20px;
      height: 20px;
    }
    .accordion-button {
      &::after {
        display: none;
      }
    }
  }
}
