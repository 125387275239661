#root-add-button {
  width: 60px;
  height: 60px;
  background-color: $purple-100;
  color: $gray-500;
  position: absolute;
  bottom: 40px;
  right: 15px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

#root-add-block {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: $bg-gray-100;
  z-index: 1000;
  transform: translate3d(0, 0, 0);
  &.active {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1) 0s, height 500ms cubic-bezier(0, 0, 0.2, 1) 0s;
    transform: translate3d(0, -100vh, 0);
  }

  .add-block-main {
    // background-color: white;
    height: 100vh;
    font-family: "Poppins", sans-serif;
    color: white;

    .wrapper {
      padding: 15px;
      padding-bottom: 0px;
      border-bottom: 1px solid #b9babd26 !important;
    }

    .title {
      font-size: 18px;
      font-weight: 500;
      display: inline-table;
      margin-top: 0px;
    }

    .top-header {
      display: flow-root;
    }

    .back-btn {
      width: 20px;
      margin-right: 20px;
      margin-bottom: 4px;
      margin-left: 5px;
    }

    ul.create-button-group {
      padding: 15px 15px 0;
      margin-bottom: 0px;

      li {
        align-items: center;
        margin-bottom: 25px;
        .link-action {
          display: flex;
          color: white;
          align-items: center;
        }
        .avatar-xs {
          background-color: #4A154B;
          border-radius: 100%;
          align-items: center;
          display: flex;
          justify-content: center;
          font-size: 22px;
        }
        span {
          padding-left: 15px;
          font-weight: bold;
        }
      }
    }

    .list-text {
      padding: 0px 15px;
      color: #acb7ca;
      font-weight: 500;
      font-size: 16px;
    }

    ul.root-user-list {
      padding: 0 15px;

      li {
        align-items: center;
        margin-bottom: 20px;
        a {
          display: flex;
          color: white;
          align-items: center;
        }
        .avatar-xs {
          border-radius: 100%;
          align-items: center;
          display: flex;
          justify-content: center;
          font-size: 18px;
        }
        span {
          padding-left: 15px;
          font-weight: bold;
        }
      }
    }
  }
}

.layout-wrapper {
  overflow: hidden;
  .side-menu {
    background-color: #ffffff !important;
    border-top: none !important;
    top: 0px !important;
    height: 124px !important;

    .chat-search-box {
      padding-bottom: 7.5px;
      .back-btn {
        width: 18px;
      }
    }
    .top-select-group-display {
      .tab-name {
        display: block;
        height: 0px;
      }
    }
    .user-chat-nav {
      i {
        font-size: 24px;
      }

      .nav-btn {
        color: white;
        width: unset !important;
        padding-left: 10px !important;
      }

      .contact-tools {
        .counter-button {
          margin-top: -14px;
          line-height: 16px;
          .btn {
            color: white;
          }
        }
      }
    }
  }
  .chat-leftsidebar {
    position: relative;
    height: calc(100vh - 124px);
    margin-top: 124px;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }
    .tab-content {
      .tab-pane.active {
        transition: transform 100ms cubic-bezier(0, 0, 0.2, 1) 0s, height 500ms cubic-bezier(0, 0, 0.2, 1) 0s;
        transform: translate3d(0px, 0px, 0px);
      }
      .tab-pane {
        display: block !important;
        transform: translate3d(-100vw, 0px, 0px);
        position: absolute;
        width: 100%;
      }
    }
  }
}

.preload {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;

  z-index: 9999;
  background-color: #37373d9e;
  img {
    margin-top: calc(50vh - 15px);
    margin-left: calc(50vw - 15px);
    width: 40px;
    height: 40px;
  }
}

.waiting-network-loading {
  display: none;
  width: 100%;
  position: fixed;
  z-index: 9999;
  font-size: 16px;
  bottom: 0;

  .loading-content {
    width: 100%;

    .loading-wrapper {
      width: 100%;
      background-color: white;
      display: flex;
      align-items: center;

      img {
        width: 57px;
        margin-left: 20px;
      }
      .note-text {
        font-size: 16px;
        font-weight: 500;
        margin-left: 20px;
      }
    }
  }

}

.preload-export {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;

  z-index: 9999;
  background-color: #37373d9e;

  .wrapper-export {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: calc(50vh);

    img {
      width: 40px;
    }

    p {
      color: white;
      margin: 0px;
      margin-left: 10px;
    }
  }
}

.preload-encrypt, .preload-decrypt {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;

  z-index: 9999;
  background-color: #37373d9e;
  img {
    margin-top: calc(50vh - 40px);
    margin-left: calc(50vw - 30px);
    width: 80px;
    height: 80px;
  }
}

// Place all the styles related to the home controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/
.notify-block {
  position: relative;
}

.notify-counter {
  width: 22px;
  height: 22px;
  background: #ef476f;
  color: #fff;
  font-size: 10px;
  border-radius: 50%;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: center;
  padding: 3px 0;
}

.request-counter {
  color: #ef476f;
  font-size: 16px;
  font-weight: bold;
}


.top-select-group-display {
  color: #7a7f9a;
  .cancel-select-group {
    position: absolute;
    display: none;
    font-size: 26px;
    line-height: 22px
  }
  .group-selected-number {
    padding-left: 60px;
  }
}

#pills-setting .copy-clipboard-btn {
  display: block;
  float: right;
  padding-left: 10px;
}

.toast-block {
  position: absolute;
  width: 100%;
  bottom: 120px;
  z-index: 9999;

  .toast {
    color: #37404a;
    text-align: center;
    margin: auto;
  }
}

.chat-message-list {
  .avatar-title {
    background-color: $purple-100;
  }
}

@media (max-width: 991.98px) {
  #listing-group-forward.chat-message-list {
      height: calc(100vh - 133px);
  }
}
