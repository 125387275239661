@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/poppins/Poppins-ExtraLight.ttf) format('opentype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/poppins/Poppins-Light.ttf) format('opentype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/poppins/Poppins-Regular.ttf) format('opentype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/poppins/Poppins-Medium.ttf) format('opentype');
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/poppins/Poppins-SemiBold.ttf) format('opentype');
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  src: url(../fonts/poppins/Poppins-Bold.ttf) format('opentype');
}
