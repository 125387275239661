#message-template-list {
  .main {
    background-color: #ffffff;
    height: 100vh;
    font-family: "Poppins", sans-serif;
    color: black;

    .wrapper {
      height: 60px;
      padding: 8px 0px;
      border-bottom: 1px solid;
      border-color: #cbc7c7;

      .top-header {
        display: flex;
        align-items: center;
      }

      .title {
        font-size: 18px;
        margin-bottom: 0px;
      }
    }
  }

  ul.listing {
    padding: 20px;
    padding-top: 0px;
    list-style: none;
    color: black;

    .form-check-input {
      margin-right: 10px;
      margin-top: 15px;
    }

    .message-title {
      font-size: 15px;
      font-weight: bold;
    }

    .message-description {
      font-size: 12px;
      font-style: italic;
    }

    .message-icon {
      position: absolute;
      right: 0px;
      top: 20px;
      font-size: 18px;
    }

    li {
      position: relative;
      padding: 13px 0px;
      border-bottom: 1px solid #303841;
    }
  }
}
