#login-page {
  position: absolute;
  width: 100%;
  z-index: 999999;
}

#user-sigin-in, .sign-up-background-common {
  .password-group {
    position: relative;
    .password-icon {
      color: $gray-500;
      position: absolute;
      right: 15px;
      top: 7px;
      font-size: 22px;
    }
    .p-show {
      display: none;
    }
    .login-loading {
      width: 35px;
      top: 5px;
      right: 10px;
      display: none;
      filter: invert(86%);
    }
  }
}

#user-sigin-in {
  .main {
    // background: url('../images/BG1_dark.png');
    // background-size: auto 135%;
    background-color: $bg-gray-100;
    height: 100vh;
    min-height: 550px;
    width: 100%;
    position: relative;
    overflow: scroll;
    font-family: "Poppins", sans-serif;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
     display: none;
    }
  }

  .wrapper {
    padding: 20px;
    min-height: 400px;
  }

  .header {
    margin-bottom: 10px;
    margin-top: 60px;

    .logo-block {
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        width: 50px;
      }

      .welcome-text {
        text-align: center;
        margin-left: 10px;
        color: $gray-500;
        font-size: 18px;
      }
    }
  }

  .block-noti {
    color: #ff5722;
  }

  .body {
    margin-top: 15px;
    display: grid;
    z-index: 100;
    position: relative;
  }

  .input-data {
    margin-bottom: 15px;
    &.username-input {
      text-transform:uppercase;
      &::placeholder {
        text-transform:capitalize;
      }
    }
  }

  .signup-block {
    margin-bottom: 5px;
    margin-top: 30px;
    text-align: center;

    .signup-content {
      color: $gray-500;
      font-size: 16px;
    }

    a.signup-btn {
      font-weight: bold;
      padding-left: 5px;
      color: $blue-100;
    }
  }

  .login-btn-group {
    .continue-btn {
      background-color: $purple-100;
      color: white;
      padding: 10px 20px;
      width: 100%;
      font-weight: 400;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 10px;
      font-size: 16px;
    }
  }
}
