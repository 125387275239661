:root {
  --co-primaryColor: #1976D2;
}

.co-color-list {
  position: absolute;
  width: calc(100vw - 48px);
  background: white;
  padding: 15px;
  z-index: 999;
  box-sizing: border-box;
}

.co-color-list::before {
  content: "";
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.co-color-list--bellow::before {
  top: -10px;
  border-bottom: 10px solid white;
}

.co-color-list--above::before {
  bottom: -10px;
  border-top: 10px solid white;
}

.co-color-list--left::before {
  left: 23px;
}

.co-color-list--right::before {
  right: 23px;
}

.co-color-list .color-pallet {
  margin: 0 0 15px;
  transition: all 450ms;
  padding: 0;
}

.co-color-list .color-pallet li {
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.co-color-list ul.color-reset {
  padding: 0;
}

.co-color-list ul.color-reset li {
  width: 100%;
  height: inherit;
  padding: 0;
}

.co-shadow-3dp {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .12);
}

.co-button-flat {
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  font-size: 14px;
  border-radius: 2px;
  color: var(--co-primaryColor);
  background: transparent;
  cursor: pointer;
  display: inline-block;
  transition: all 400ms;
  text-transform: uppercase;
  text-align: center;
}

.co-width-100-percentage {
  width: 100%;
}

.co-font-color-black-54 {
  color: rgba(0, 0, 0, .54);
}
