.main-sidebar {
  transform: translateX(100%);
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: black;
  width: 100%;
  height: 100%;
  transition: all .4s;
}

.main-sidebar.show {
  transform: translateX(0);
  visibility: visible;
}