#open-note-file, #open-photo-file, #open-conversation-file, #open-secured-book {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: black;
  z-index: 1000;
}

.open-note-main {
  background-color: #f5f7fb;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  color: black;

  .wrapper-header {
    height: 60px;
    padding: 8px 0px;
    border-bottom: 1px solid;
    border-color: #cbc7c7;
  }

  .top-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .content {
    padding: 20px;
    background-color: white;
    color: black;
    height: 80vh;
    width: 100vw;
    border: none;
  }

  .title {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 0px;
    word-break: break-all;
    width: 100%;
  }

  #save-note {
    position: absolute;
    bottom: 22px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    left: calc(50% - 25px);
    display: flex;
    justify-content: center;
    i {
      font-size: 24px;
    }
  }
}

.open-photo-main {
  background-color: #000000;
  height: 100vh;
  color: white;

  .top-header {
    text-align: right;
    font-size: 32px;
    padding: 10px;
    padding-bottom: 5px;
  }

  .photo-src {
    width: 100%;
  }

  .back-btn {
    width: 15px;
    margin-right: 20px;
  }

  #save-photo {
    position: absolute;
    bottom: 22px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    left: calc(50% - 25px);
    display: flex;
    justify-content: center;
    i {
      font-size: 24px;
    }
  }
}
