#preload {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  display: table;
  width: 100%;
}

#preload .text {
  display: table-cell;
  vertical-align: middle;
  color: #dcdcdc;
  font-size: 24.08px;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
#preload .text .preload-progress {
  vertical-align: top;
}
#preload .kre {
  right: 0;
  animation-name: kre;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: both;
}

#preload .mis {
  left: 0;
  animation-name: mis;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: both;
}

#preload .preload-progress {
  display: inline-block;
  position: relative;
  width: 33.25px;
  margin-right: 21px;
}
#preload .mis,
#preload .kre {
  width: 28px;
  position: absolute;
}

@keyframes mis {
  0% {
    transform: scale(1.2);
    opacity: .75;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: .75;
  }
}

@keyframes kre {
  0% {
    transform: scale(1);
    opacity: .75;
  }

  50% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: .75;
  }
}