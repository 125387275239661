#dark-mode {
  color: white;

  .chat-leftsidebar {
    background-color: $bg-gray-100;
  }

  // common
  .btn-light {
    background-color: #4A154B;
    color: white;
  }

  .text-body {
    color: #93a1ae !important;
  }

  .form-control {
    color: white;
  }

  input[type=text]::placeholder, input[type=password]::placeholder {
    color: #ababab;
  }

  textarea.enter-messsage-content::placeholder {
    color: #ababab;
  }

  .common-text-color {
    color: white;
  }

  h5 {
    color: white;
  }
  h6 {
    color: white;
  }
  .text-muted {
    color: #C4C4C4 !important;
  }

  // modal
  .modal .modal-content {
    background: $bg-gray-100;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15), 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-color: $gray-100;
    .modal-title {
      color: $gray-100;
    }

    .modal-body {
      color: $gray-100;
    }

    textarea {
      color: white;
      background: $bg-gray-500;
      border: 1px solid rgba(255, 255, 255, 0.12);
    }
  }
  // model end

  .rad-text {
    color: white;
  }

  // start modify botto menu
  //=========================
  .side-menu {
    background-color: $purple-100 !important;
  }

  .side-menu-nav .nav-item {
    .nav-link.active {
      background-color: transparent;
      color: white;
      border-bottom: 2px solid;
      border-radius: 0;
    }
    .nav-link {
      color: #b9babd;
      font-size: 28px;
    }
  }
  //=========================
  // end modify botto menu

  // setting page
  #lifetime-duration-label {
    color: #c4c4c4;
  }
  #pills-setting {
    background: url(../images/template_dark.jpg);
    background-size: 100%;
    background-repeat: no-repeat;

    .top-page .row.wallet-group .wallet-col {
      background: rgba(250, 250, 250, 0.1);
      box-shadow: 8px 0px 18px rgba(0, 0, 0, 0.25);
    }
    .accordion-item {
      .accordion-button {
        background-color: #292929;
        .title-text {
          background: #3c3c3c;
          box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }

      .accordion-button:not(.collapsed) {
        .title-text {
          background: #292929;
          box-shadow: none;
          color: white;
        }
      }

      .accordion-button:not(.collapsed)::after {
        background-image: url(../images/white-down-arrow.png);
      }
      .accordion-button::after {
        background-image: url(../images/white-down-arrow.png);
      }

      .accordion-body {
        background-color: #292929;
        .all-my-info {
          background: linear-gradient(180deg, #404040 0%, #2E2E2E 100%);
        }
      }
    }
  }

  // vault page

  #pills-vaults .listing-vault-tabs {
    background-color: $bg-gray-100;
    height: calc(100vh - 124px);
    overflow-y: scroll;
  }

  .file-tab .gallery-column .each-block, .secured-book-tab .gallery-column .each-block, .conversation-tab .gallery-column .each-block {
    background-color: $gray-btn;
  }

  // contact page
  #pills-contacts .contact-list, #pills-requests .contact-list {
    color: $gray-500;
  }

  // group, chat list page
  .chat-list li .chat-user-message {
    color: #8f8f94;
  }

  .chat-message-list {
    &.avatar-title {
      background-color: #4A154B;
    }
    &.chat-list li .round label {
      background-color: #fff0;
    }

    .room-list {
      padding: 10px 0px;
    }
  }

  // conversation page
  .user-chat {
    background-color: #292929;
  }

  #user-chat-show .chat-conversation, #attachment-chat .chat-conversation {
    background: url(../images/chat-bg-black.jpg);
    background-size: cover;
  }

  #user-chat-show ul#my-chat-history li.date-break-line p.date-content, #attachment-chat ul#my-chat-history li.date-break-line p.date-content {
    color: white;
  }

  #user-chat-show .chat-conversation .template .wrapper, #attachment-chat .chat-conversation .template .wrapper {
    background-color: #525357a8;
  }

  .border-bottom {
    border-bottom: 1px solid $boder-gray-100 !important;
  }

  .border-bottom.reply-section-content {
    border-bottom: 0px solid #c8c8c8 !important;
  }

  .chat-input-section {
    background-color: $bg-gray-100;
  }

  .chat-conversation .conversation-list {
    .chat-time {
      color: #d0d0d0;
    }

    .conversation-name {
      color: white;
    }

    .right .chat-time {
      color: #d0d0d0;
    }

    .ctext-wrap-content {
      color: white;
      background-color: #525357;
    }
    .ctext-wrap-content:before {
      border-left-color: #525357;
      border-top-color: #525357;
    }
  }
  .chat-conversation .right .conversation-list {
    .ctext-wrap-content {
      background-color: #607D8B;
      color: #fff;
    }

    .ctext-wrap-content:before {
      border: 5px solid transparent;
      border-top-color: #607D8B;
      border-right-color: #607D8B;
      left: auto;
      right: 0;
    }
  }

  .dropdown-menu {
    background: $bg-gray-500;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12), 0px 12px 24px rgba(0, 0, 0, 0.12), 0px 1px 32px rgba(0, 0, 0, 0.12);

    .dropdown-item {
      color: #b2b2b8;
    }
  }

  #user-chat-show ul#my-chat-history li .round label, #attachment-chat ul#my-chat-history li .round label {
    background-color: #fff0;
    border: 2px solid #ccc;
  }

  #user-chat-show ul#my-chat-history li .round input[type=checkbox]:checked + label, #attachment-chat ul#my-chat-history li .round input[type=checkbox]:checked + label {
    border-color: $green-500;
  }

  #user-chat-show .chat-conversation.reply-section-open, #user-chat-show .chat-conversation.forward-section-open {
    height: calc(100vh - 223px);
  }

  .card {
    border: none !important;
    .card-header {
      background-color: #4A154B;
      border-bottom: 0 solid #4A154B;
      h5 {
        color: white;
      }
    }

    .card-body {
      background-color: $bg-gray-500;
      .contact-list {
        padding-top: 5px;
        color: white;
      }
    }
  }

  .forward-chat, .attachment-chat {
    background-color: $bg-gray-100;
    .attachment-topbar {
      border-bottom: 1px solid #b9babd26 !important;
    }
    .forward-chat-remove {
      font-size: 29px;
      color: $gray-500;
      margin-right: 10px;
      font-weight: bold;
    }
    .back-btn {
      margin-top: 3px;
    }
  }

  .sign-up-background-common {
    background-color: $bg-gray-100;
    color: white;

    .top-header {
      .title {
        color: white;
        font-weight: bold;
      }
      .back-btn {
        filter: none;
        width: 18px;
      }
    }
    // input {
    //   background-color: #2b2b2c;
    //   border: 1px solid #9E9E9E;
    //   color: white;
    // }
    // input:focus {
    //   border: 2px solid #4A154B;
    // }
    .error-listing {
      color: white;
    }
  }


  // wallet
  .wallet-container {
    .voucher-list {
      background-color: #fff0;
      .voucher-card {
        background-image: linear-gradient(to right, #040303 , #848282);
        border: none;
        box-shadow: 0 4px 6px 0 rgb(248 243 243 / 20%);
        .content {
          color: white;
          h1 {
            color: white;
          }
        }
        .copy-button {
          input {
            background-color: unset;
            border: none;
          }
        }
      }
    }
  }

  // message template

  #message-template-list, #message-template-list .main, #transaction-list, .wallet-container {
    background-color: $bg-gray-100;
    color: white;

    .top-header {
      .title {
        color: white;
        font-weight: bold;
      }
      .back-btn {
        filter: none;
        width: 18px;
      }
    }

    a {
      color: white;
    }
    ul.listing {
      color: white;
    }
  }

  // user profile
  .user-profile-sidebar {
    background-color: $bg-gray-100;

    .accordion-button:not(.collapsed) {
      background-color: #4A154B;
    }
    h5 {
      color: white;
    }

    .accordion-body {
      background-color: #292929;
    }
  }

  // open note
  .open-note-main {
    background-color: #292929;
    color: white;
    .content {
      background-color: #3d3d3e;
      color: white;
    }
  }

  // sucured book
  .edit-secured-book-main {
    background-color: #292929;
    color: white;

    .top-header {
      color: white;
    }

    input.title {
      color: white;
    }
    .spreadsheet-wrapper .spreadsheet {
      thead {
        background-color: #3d3d3e;
      }
      tbody th {
        background-color: #3d3d3e;
      }
      td {
        background: #292929;
      }

      .dropdown-content {
        background-color: #263238;
        p {
          color: #b2b2b8;
        }
      }
    }

    .continue-btn {
      color: #c7c2c2;
      &.active {
        color: $blue-100;
      }
    }
  }
}
