.loading-voice-block {
  .custom-voice-block {
    width: 218px;
    height: 65px !important;
  }

  .btn-shine {
    left: 180px !important;
  }
}

.loading-text-block {
  .ctext-wrap-content {
    margin-bottom: 27px;
  }
  .custom-text-block {
    min-width: 100px;
    height: 22.5px !important;
  }

  .btn-shine {
    top: 15px !important;
  }
}

.loading-voice-block, .loading-text-block {
  .loading-image {
    height: 45px;
    padding-right: 105px
  }
  .btn-shine {
    position: absolute;
    top: 27px;
    left: 61px;
    transform: translate(-50%, -50%);
    color: #fff;
    background: linear-gradient(to right, #8b8888 0, #fff 10%, #f0e4e4 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 1s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    font-weight: 600;
    text-decoration: none;
    white-space: nowrap;
  }
  @keyframes shine {
    0% { background-position: 0; }
    60% { background-position: 90px; }
    100% { background-position: 180px; }
  }
}
// Place all the styles related to the conversations controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/
.list-inline-item:not(:last-child) {
  margin-right: 0 !important;
}
.each-message-photo {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#message-content, #attachment-section {
  .enter-messsage-content::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $gray-100;
    opacity: 0.8; /* Firefox */
  }
  .enter-messsage-content {
    font-family: SegoeuiPc,Segoe UI,San Francisco,Helvetica Neue,Helvetica,Lucida Grande,Roboto,Ubuntu,Tahoma,Microsoft Sans Serif,Arial,sans-serif;
    resize: none;
    border: none;
    padding: 10px 0px;
    &::placeholder {
      font-family: 'Poppins';
    }
  }
}
#user-chat-show,
#attachment-chat {
  #message-content {
    .chat-input-links {
      margin-top: 5px;
      .tool-box {
        display: inline;
      }
    }

    .voice-record-main {
      display: none;
      .recording-wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 7px;

        .delete-btn {
          color: $gray-100;
          font-size: 22px;
          padding-right: 8px;
        }

        .send-voice-btn {
          color: $blue-100;
          font-size: 22px;
          padding-left: 8px;
        }

        .record-block {
          width: 100%;
          background: rgba(118, 118, 128, 0.1);
          border-radius: 18px;
          align-items: center;
          color: #3C3C43;
          position: relative;

          .stop-btn {
            color: $gray-100;
            font-size: 24px;
            padding-left: 7px;
            display: none;
            float: left;
            width: 31px;
          }

          .play-btn {
            color: $gray-100;
            font-size: 24px;
            padding-left: 7px;
            display: none;
            float: left;
            width: 31px;
          }

          .pause-btn {
            color: $gray-100;
            font-size: 24px;
            padding-left: 7px;
            display: none;
            float: left;
            width: 31px;
          }

          #waveform {
            margin-left: 5px;
            position: relative;
            width: calc(100% - 100px);
            float: left;
          }

          p.record-text {
            display: block;
            position: absolute;
            top: 8px;
            left: 38px;
          }

          p.time-text {
            color: $gray-100;
            position: absolute;
            top: 8px;
            right: 38px;
          }

          .pulsating-circle {
            position: absolute;
            top: 18px;
            right: 4px;
            transform: translateX(-50%) translateY(-50%);
            width: 15px;
            height: 15px;

            &:before {
              content: '';
              position: relative;
              display: block;
              width: 300%;
              height: 300%;
              box-sizing: border-box;
              margin-left: -100%;
              margin-top: -100%;
              border-radius: 45px;
              background-color: white;
              animation: pulse-ring 1s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
            }

            &:after {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              display: block;
              width: 100%;
              height: 100%;
              background-color: red;
              border-radius: 15px;
              box-shadow: 0 0 8px rgba(0,0,0,.3);
              animation: pulse-dot 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
            }
          }

          @keyframes pulse-ring {
            0% {
              transform: scale(.33);
            }
            80%, 100% {
              opacity: 0;
            }
          }

          @keyframes pulse-dot {
            0% {
              transform: scale(.8);
            }
            50% {
              transform: scale(1);
            }
            100% {
              transform: scale(.8);
            }
          }
        }
      }
    }
  }

  .chat-input-section, .attachment-chat-section {
    border-top: 1px solid #b9babd4a;
    border-radius: 15px 15px 0px 0px;
    ul.list-inline{
      .set-delete-time {
        padding: 1px 10px;
        border-radius: 15px !important;
        margin-top: 13px;
        font-size: 13px;
        i {
          vertical-align: middle;
        }
      }
      .float-end {
        li.list-inline-item {
          i {
            font-size: 21px;
          }
        }
      }
    }
  }

  ul#my-chat-history {
    margin-bottom: 0px;
    margin-left: -16px;
    margin-right: -16px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    li.conversation-messages {
      transition: background-color 0.1s ease-in-out;
      margin-bottom: 10px;
    }
    li:active {
      background-color: rgba(0, 160, 176, 0.32);
      -webkit-transition: background-color 300ms linear;
      -ms-transition: background-color 300ms linear;
      transition: background-color 0.2s linear;
    }
    li.right {
      .user-chat-content {
        h5 {
          color: white;
        }
      }
      .border-bottom {
        border-color: $gray-100 !important;
      }
      .text-success {
        margin-bottom: 2px;
        color: white !important;
      }
    }
    li.left {
      .media-button {
        color: #40adaa !important;
      }
      .border-bottom {
        border-color: gray !important;
      }
      .voted {
        background-color: #4A154B !important;
      }
      .text-success {
        margin-bottom: 2px;
        font-weight: 500;
        color: $gray-100 !important;
      }
    }
    li {
      transition: all .1s;
      position: relative;
      overflow: hidden;
      z-index: 1;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: -2;
      }
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: #40adaa3d;
        transition: all .1s;
        z-index: -1;
      }
      &.clicked {
        color: #fff;
        &:before {
          width: 100%;
        }
      }
      .round.open {
        width: 35px;
        visibility: visible;
        label {
          display: block;
        }
      }
      .round {
        visibility: hidden;
        position: relative;
        float: left;
        margin-top: 23px;
        width: 0px;
        -webkit-transition: width 0.2s ease-in-out;
        -moz-transition: width 0.2s ease-in-out;
        -o-transition: width 0.2s ease-in-out;
        transition: width 0.2s ease-in-out;

        label {
          display: none;
          background-color: #fff;
          border: 1px solid #ccc;
          border-radius: 50%;
          cursor: pointer;
          height: 22px;
          left: 0;
          position: absolute;
          top: 0;
          width: 22px;
          transition: all 0.2s linear;
        }

        label:after {
          border: 2.4px solid $green-500;
          border-top: none;
          border-right: none;
          content: "";
          height: 6px;
          left: 5px;
          opacity: 0;
          position: absolute;
          top: 2px;
          transform: rotate(-45deg);
          width: 17px;
        }

        input[type="checkbox"] {
          visibility: hidden;
        }

        input[type="checkbox"]:checked + label {
          border-color: #4A154B
        }

        input[type="checkbox"]:checked + label:after {
          opacity: 1;
        }
      }

      .conversation-list {
        margin-bottom: 0px;
        margin-top: 0px;
        padding-bottom: 10px;
        display: contents;

        .seen-user-list {
          font-size: 12px;
          color: gray;
          display: none;
        }

        .message-img {
          padding: 0px 7px;
        }

        .text-success {
          font-size: 14px;
          margin-bottom: 2px;
        }

        .user-chat-content {
          .each-file, .each-secured-book {
            display: flow-root;
            padding: 10px;
            background-color: #026c6ccf;
            border-radius: 5px;
            margin-bottom: 5px;
            .file-icon {
              font-size: 18px;
              background-color: white;
              color: #4A154B;
              border-radius: 100%;
              margin-right: 10px;
              width: 32px;
              height: 32px;
              text-align: center;
              float: left;
              padding-top: 2px;
            }
            .file-detail {
              text-align: left;
              display: inherit;
              color: white;
              p {
                margin-bottom: 0px;
              }
            }
          }
          .btn:hover {
            color: initial;
          }
          .voted {
            background-color: #cfcbcb;
            color: white !important;
          }
          .ctext-wrap-content {
            word-wrap: break-word;         /* All browsers since IE 5.5+ */
            overflow-wrap: break-word;     /* Renamed property in CSS3 draft spec */
            max-width: 100%;
            padding: 5px 10px;
            .message-content {
              white-space: break-spaces;
              text-align: left;
            }
          }

          .voice-block {
            height: 40px;
            .media-button {
              float: left;
              font-size: 25px;
              color: white;
              margin-right: 5px;
              margin-left: -7px;
              .ri-pause-fill {
                display: none;
              }
            }
            .wave-block {
              width: 150px;
              float: left;
            }
            .voice-duration {
              float: left;
              padding-top: 9px;
              font-size: 12px;
              width: 45px;
              text-align: right;
            }
          }
        }
      }
    }

    li.date-break-line {
      display: flex;
      padding: 15px;
      p.date-content {
        background-color: #4A154B52;
        color: #454242;
        padding: 3px 8px;
        border-radius: 8px;
        margin: auto;
        font-size: 13px;
      }
    }
  }
  .chat-conversation {
    background: url('../images/chat_bg_light_new.jpg');
    background-size: cover;
    height:calc(100vh - 156px);
    margin-top: 60px;
    // for init message
    .template {
      height: calc(100vh - 200px);
      display: flex;
      align-items: center;

      .wrapper {
        width: 75%;
        margin: 0 auto;
        padding: 10px;
        background-color: #eef1f6;
        border-radius: 15px;
      }
    }
    .send-status {
      font-size: 16px;
      color: $gray-100;
    }
    .conversation-list {
      .message-img {
        .message-img-list {
          img, video {
            vertical-align: middle;
            max-width: calc(50vw - 20px);
          }
        }
        .message-img-list.only-one-image {
          img, video {
            width: calc(100vw - 40px);
            max-width: 300px;
          }
        }
      }
    }
  }

  .left.col-10 {
    width: 89%;
    padding-left: 0px;
  }

  .user-chat-topbar {
    height: 60px;
    background-color: $purple-100;
    // border-bottom: 1px solid #b9babd26;
    .chat-checkbox-topbar {
      height: 42px;

      .chat-message-delete, .chat-message-copy-clipboard {
        i {
          font-size: 24px;
        }
      }

      .chat-message-copy-clipboard  {
        margin-right: 20px;
      }

      .close-and-count-block {
        position: relative;
        color: $gray-500;
      }

      .chat-checkbox-topbar-remove {
        position: absolute;
        i {
          font-size: 26px;
          line-height: 21px
        }
      }

      .message-selected-number {
        padding-left: 60px;
        font-size: 20px;
      }
    }

    .chat-topnav {
      .user-chat-remove {
        font-size: 29px;
        font-weight: bold;
        display: flex;
        color: white;
      }
      .room-name-block {
        padding-left: 10px;
        text-transform: capitalize;
      }
      .nav-btn {
        color: white;
        font-size: 24px;
      }
    }
  }
}

.chat-user-message-unread {
  font-weight: bold;
  color: #4A154B;
}

#chat-send,
#attachment-chat-send {
  background-color:  unset;
  border-color: transparent;
  color: $blue-100;
}

#chat-send:focus {
  box-shadow: none;
}

.start-record {
  background-color: transparent;
}
.start-record:active {
  background-color: #40adaa3d;
}

#chat-forward {
  background-color:  unset;
  border-color: transparent;
  color: rgb(166, 176, 207);
}

.display-countdown-lifetime {
  color: $green-500;
  padding-right: 3px;
}

// set lifetime modal
.rad-label {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 0px 5px;
  margin: 10px 15px;
  cursor: pointer;
  transition: .3s;
  justify-content: start;
}

.rad-label:hover,
.rad-label:focus-within {
  background: hsla(0, 0%, 80%, .14);
}

.rad-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.rad-design {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: $blue-100;
  position: relative;
  font-size: 0px;
}

.rad-design::before {
  content: '';

  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;

  background: white;
  border: 1px solid #6C6C70;
  transform: scale(1.1);
  transition: .3s;
}

.rad-input:checked+.rad-design::before {
  transform: scale(0);
}

.rad-text {
  color: black;
  margin-left: 15px;
  font-size: 15px;
  transition: .3s;
  font-weight: 400;
  text-align: center;
  padding: 5px 0px;
}

.rad-input:checked~.rad-text {
  color: #4A154B;
}

.container.container {
  margin-top: 30px;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media(max-width: 864px) {
    margin-left: 5px;
  }

  @media(min-width: 1024px)  {
    margin-left: 35px;
  }
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;

  border-radius: 15px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkbox {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkbox {
  background-color: #7269ef;
  left: 3px;
}

/* Create the checkbox/indicator (hidden when not checked) */
.checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkbox when checked */
.container input:checked ~ .checkbox:after {
  display: block;
}

/* Style the checkbox/indicator */
.container .checkbox:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.delete-message {
  background-color: #29333c;
  --bs-gutter-x: 30px !important;
}

.forward-chat,
.attachment-chat {
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 4px rgba(15,34,58,.12);
  box-shadow: 0 2px 4px rgba(15,34,58,.12);
  -webkit-transition: all .4s;
  transition: all .4s;
}

@media (max-width:991.98px) {
  .forward-chat,
  .attachment-chat,
  .attachment-camera-chat {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    z-index: 99;
  }
  .forward-chat.forward-chat-show,
  .attachment-chat.attachment-chat-show,
  .attachment-camera-chat.attachment-camera-chat-show {
    visibility: visible;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.right .forward-text {
  text-align: right;
}
.forward-text {
  font-size: 12px;
  margin-top: 4px;
}

.reply-section {
  display: none;
}

.reply-section,
.forward-section {
  margin-bottom: 10px;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0px;

    &.text-primary {
      font-weight: bolder;
      color: $gray-100 !important;
    }
  }

  span.text-primary {
    font-size: x-large;
    margin-top: 2px;
    margin-left: -2px;
    margin-right: 2px;
    font-weight: bolder;
    color: $gray-100 !important;
  }

  .close-reply-section,
  .close-forward-section {
    margin-left: -12px;
    font-size: x-large;
    margin-top: 2px;
  }
}

#user-chat-show .chat-conversation.reply-section-open,
#user-chat-show .chat-conversation.forward-section-open {
  height: calc(100vh - 255px);
}


.border-bottom.reply-section-content-photo {
  display: flex;
  .image-block {
    float: left;
    margin-right: 12px;
    img, video {
      height: 42px;
    }
  }

  .right-block {
    text-align: left;
  }
}

.right {
  .border-bottom.reply-section-content {
    p:not(.text-success) {
      color: white;
    }
  }
}
.border-bottom.reply-section-content {
  border-bottom: 0px solid !important;
  border-left: 3px solid;
  padding-left: 5px;
  border-radius: 3px;

  p {
    font-size: 12px;
    white-space: break-spaces;
  }

  p:not(.text-success) {
    margin-bottom: 10px;
    font-style: italic;
    color: #9e9e9e;
    font-size: 13px;
  }

  .poll-icon-text {
    display: inline-flex;
    .chart-icon {
      margin-right: 2px;
    }
  }
}

.forward-chat-content {
  display: none;
}
