#lifetime-duration-label {
  font-size: 14px;font-weight: 300;color: #6C6C90;
}

#show-seed-modal .seed-value {
  width: 100%;
  padding: 5px;
  border-color: #4A154B;
}

#settings-block {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: $bg-gray-100;
  z-index: 1000;
  transform: translate3d(0, 0, 0);
  &.active {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1) 0s, height 500ms cubic-bezier(0, 0, 0.2, 1) 0s;
    transform: translate3d(0, -100vh, 0);
  }

  .setting-block-main {
    // background-color: white;
    height: 100vh;
    font-family: "Poppins", sans-serif;
    color: white;

    .wrapper {
      padding: 15px;
      padding-bottom: 0px;
      border-bottom: 1px solid #b9babd26 !important;
      .title {
        font-size: 20px;
        font-weight: 500;
        display: inline-table;
        margin-top: 0px;
      }

      .top-header {
        display: flow-root;
      }

      .back-btn {
        width: 20px;
        margin-right: 20px;
        margin-bottom: 5px;
      }
    }

    .main-body {
      height: calc(100vh - 59px);
      .top-page {
        color: white;
        position: relative;
        h3, h4 {
          color: white;
        }
        h1 {
          color: #4A154B;
        }
        .more-option {
          position: absolute;
          top: 17px;
          right: 15px;
          button {
            font-size: 24px;
            padding: 0px;
            color: white;
          }
        }

        .payment-button {
          color: white;
        }

        .top-button {
          background-color: white;
          border-radius: 100%;
          width: 42px;
          height: 42px;
          line-height: 42px;
          margin: auto;
          margin-bottom: 5px;
        }

        .alert-fund {
          padding: 5px;
          font-size: 16px;
          color: $gray-500;
          text-align: center;
          margin-bottom: 0px;
          background: $purple-100;
          border-radius: 20px;
        }

        .row.wallet-group {
          --bs-gutter-x: 0px;
          display: grid;
          grid-template-columns: auto auto auto;
          grid-column-gap: 7px;
          .wallet-col {
            padding: 10px;
            border: 1px dotted;
            border-radius: 5px;
            background-color: #ffffff2e;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: scroll;
            .btn-link {
              color: #a6b0cf;
            }
            &::-webkit-scrollbar {
              -webkit-appearance: none;
              width: 0;
              height: 0;
            }
          }
        }
      }
      .header-block-title {
        color: white;
        font-weight: bold;
        padding: 15px;
        font-size: 15px;
      }
      hr {
        padding: 0;
        margin: 0;
        height: 0.1px;
      }
      .block-content {
        padding: 10px 15px;
        .each-setting-block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 0px;
          .setting-icon {
            font-size: 24px;
            color: $gray-500;
            padding-right: 4px;
          }
          .left-block {
            display: flex;
            align-items: center;
            .square {
              width: 30px;
              height: 30px;
              margin-right: 10px;
              font-size: 24px;
              color: $gray-500;
              margin-bottom: 8px;
            }
            .title-text {
              font-size: 16px;
              color: $gray-500;
              margin: 0;
            }
            .content-text {
              font-size: 15px;
              color: $gray-100;
              font-weight: 300;
            }
          }
          img {
            height: 26px;
            filter: brightness(50);
          }
        }
      }
    }
  }
}

#set-timezone-box {
  .select {
    position: relative;
    width: 100%;
  }
  .select svg {
    position: absolute;
    right: 12px;
    top: calc(50% - 3px);
    width: 10px;
    height: 6px;
    stroke-width: 2px;
    stroke: #9098a9;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    pointer-events: none;
  }
  .select select {
    -webkit-appearance: none;
    color: #757272;
    padding: 7px 40px 7px 12px;
    width: 100%;
    border: 1px solid #9e9e9e;
    border-radius: 5px;
    background: transparent;
    box-shadow: 0 1px 3px -2px #9098a9;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    transition: all 150ms ease;
  }

  .select select:focus {
    outline: none;
    box-shadow: none;
  }
  .sprites {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
  }
}
