.confirm-friend-modal {
  position: fixed;
  background-color: $bg-gray-100;
  color: $gray-500;
  z-index: 99;
  top: 0;
  height: 100%;
  height: 100%;
  overflow: scroll;
  display: none;

  .main-container {
    padding: 15px;

    .header {
      display: flex;
      align-items: center;

      .close-btn {
        color: $gray-500;
      }

      .title {
        display: block;
        margin: auto;
        padding-right: 20px;
        font-size: 20px;
      }
    }
    .avatar-block {
      width: 150px;
      height: 150px;
      background-color: white;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px auto;
    }

    .each-block {
      text-align: center;
      margin-bottom: 20px;
    }

    .introduction {
      border: 1px dotted;
      display: inline-block;
      padding: 1px 5px;
    }

    .warning-text {
      text-align: center;
    }

    .button-block {
      i {
        margin-right: 5px;
      }
      margin-top: 50px;

      .accept-btn {
        width: 100%;
        background-color: $blue-100;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 10px;
        border: none;
        color: $gray-500;
        font-weight: bold;
      }
      .ignore-btn {
        width: 100%;
        background-color: $red-500;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        padding: 10px;
        font-weight: bold;
      }
    }
  }
}
